// DisableContextMenu.tsx

import React, { useEffect } from "react";

const DisableContextMenu: React.FC = () => {
  const handleContextMenu = (e: MouseEvent) => {
    e.preventDefault();
  };

  useEffect(() => {
    // Add event listener to disable context menu on mount
    window.addEventListener("contextmenu", handleContextMenu);

    return () => {
      // Clean up the event listener on unmount
      window.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return null; // This component doesn't render anything
};

export default DisableContextMenu;
