import client from "./interseptors";
import { signInData } from "../components/auth/Login";

export async function signIn(data: signInData){
    try{
      const response = await client.post("api/auth/signin", data)
      if(response.data.statusCode === "error") return response
      if (response.data.data.tokens.access_token && response.data.data.tokens.refresh_token){
        localStorage.setItem("accessToken", response.data.data.tokens.access_token)
        localStorage.setItem("refreshToken", response.data.data.tokens.refresh_token)
        console.log("hi!")
      }
      return response
    } catch(error){
      throw error
    }
  }