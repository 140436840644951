import React, { useEffect, useState } from "react";
import {
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Cards from "./Cards";
import avia from "../assets/imageManager.jpg";
import { withProtectedRoute } from "../hocs/ProtectedRoute";
function HomeFile() {
  const Size = useBreakpointValue({
    base: "100%",
    sm: "100%",
    md: "100%",
    lg: "90",
  });
  const SizeVideo = useBreakpointValue({
    base: "300px",
    sm: "100px",
    md: "100%",
    lg: "100%",
  });
  const margint = useBreakpointValue({
    base: "17%",
    sm: "17%",
    md: "17%",
    lg: "5%",
  });
  const isMobile = useBreakpointValue({ base: true, md: false });


  return (
    <Grid templateRows={"repeat(12, 1fr)"} h="100vh" fontWeight="bold" w={Size}>
      <GridItem rowSpan={1} w={Size} h="15%">
        <NavBar />
      </GridItem>
      {/* {currentUrl} */}

      <GridItem bg="#fff4f4" color="black" w={Size}>
        <Center>
          <Flex
            mt={margint}
            w="70%"
            justifyContent="center"
            alignItems="center"
            direction="column"
            dir="rtl"
          >
            <Image mt={isMobile ? 3 : 0} borderRadius="full" boxSize="200px" src={avia} alt="Image" />
            <Center textAlign="center">
              <Text>
                <br />
                שלום לכולם, איזה כיף שאתם מצטרפים אליי לסדנא.
                <br />
                הסדנא עם המוצר הכי הכי מדובר, הקרואסון! ולא סתם קרואסון, הדרך
                לקרואסון המושלם.
                <br />
                למי הקורס מתאים?
                <br />
                הסדנא מתאימה לכולם! מה אופה הביתי ועד לאופה המקצועי, בסדנא תקבלו
                טיפים איך לעבוד מהבית וטיפים איך לייעל את העבודה שלכם בין אם
                בבית ובין אם בעסק.
                <br />
                אודות המרצה
                <br />
                אז שוב שלום, אני אביה אדר, הפכתי את התחביב למקצוע, למדתי באופן
                מקצועי עברתי סטאז' וכל הזמן רק שיפרתי את עצמי ואספתי את הסודות
                שלי, ובאתי לגלות לכם את כל הסודות שאספתי, מישראל ועד פריז.
                <br />
              </Text>
            </Center>
          </Flex>
        </Center>
        <Cards />
      </GridItem>

      <GridItem rowSpan={1}>
        <Divider />
        <Footer />
      </GridItem>
    </Grid>
  );
}

export default withProtectedRoute(HomeFile);
