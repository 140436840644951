import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Stack,
  VStack,
  Text,
  Link,
  InputGroup,
  InputRightElement,
  useBreakpointValue,
  ButtonGroup,
  IconButton,
  Icon,
  SimpleGrid,
  useColorModeValue,
  GridItem,
  Spacer,
  Image,
  AspectRatio,
  InputLeftElement,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Divider,
} from "@chakra-ui/react";
import {
  GetUsers,
  User,
  signUpUser,
  updateUsers,
  DeleteUser,
} from "../../api/Home";
import { withAdminProtectedRoute } from "../../hocs/AdminProtectedRoute";
import { ZodType, z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormField } from "../auth/FormField";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import Fuse from "fuse.js";
import { useNavigate } from "react-router-dom";
import { BsBoxArrowUpRight, BsFillTrashFill } from "react-icons/bs";
import { AiFillEdit, AiTwotoneLock } from "react-icons/ai";
import NavBar from "../NavBar";
import { Radio, RadioGroup } from "@chakra-ui/react";
import MobileAdminPanel from "./MobileAdminPanel";
import Footer from "../Footer";
import DeleteDialog from "../AlertDialogs/DeleteDialog";
import SuccessToast from "../AlertDialogs/SuccessToast";
import ErrorToast from "../AlertDialogs/ErrorToast";
import { Dispatch, SetStateAction } from "react";
import notFoundGif from "../../assets/userNotFoundGIF.gif";
import { useStore } from "../../api/store";

export type FormAuthData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  isAdmin: boolean;
  isGold: boolean;
  isSilver: boolean;
};

export type FormEditData = {
  email: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  isGold: boolean;
  isSilver: boolean;
};

const AdminPanel = () => {
  const navigate = useNavigate();
  const {
    data: usersData,
    isLoading,
    isError,
    refetch,
  } = useQuery<User[]>(["users"], GetUsers, { staleTime: Infinity });

  const [editingUser, setEditingUser] = useState<User | null>(null);

  const { users, setUsers } = useStore();

  const handleEdit = (user: User) => {
    if (editingUser) {
      setEditingUser(null);
    }

    setEditingUser(user);
    reset(user);
  };

  useEffect(() => {
    if (usersData) {
      setUsers(usersData);
    }
  }, [usersData, setUsers]);

  useEffect(() => {
    if (users && users.length > 0) {
      setEditingUser(users[0]);
    }
  }, [users]);

  const SignupSchema: ZodType<FormAuthData> = z.object({
    email: z.string().email().min(8).max(30),
    password: z.string().min(4).max(30),
    firstName: z.string().min(2).max(30),
    lastName: z.string().min(2).max(30),
    isAdmin: z.boolean(),
    isGold: z.boolean(),
    isSilver: z.boolean(),
  });

  const editUserSchema: ZodType<FormEditData> = z.object({
    email: z.string().email().min(8).max(30),
    firstName: z.string().min(2).max(30),
    lastName: z.string().min(2).max(30),
    isAdmin: z.boolean(),
    isGold: z.boolean(),
    isSilver: z.boolean(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm<FormAuthData>({
    resolver: zodResolver(SignupSchema),
  });

  const {
    register: registerEdit,
    handleSubmit: handleEditSubmit,
    formState: { errors: errorsEdit },
    reset,
    setValue,
  } = useForm<FormAuthData>({
    resolver: zodResolver(editUserSchema),
    defaultValues: {
      // Add this block
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      isAdmin: false,
      isGold: false,
      isSilver: false,
    },
  });

  useEffect(() => {
    if (editingUser) {
      setValue("email", editingUser.email);
      setValue("firstName", editingUser.firstName);
      setValue("lastName", editingUser.lastName);
      setValue("isAdmin", editingUser.isAdmin);
      setValue("isGold", editingUser.isGold);
      setValue("isSilver", editingUser.isSilver);
    }
  }, [editingUser, setValue]);
  const [successToastType, setSuccessToastType] = useState<
    "added" | "updated" | "deleted" | null
  >();
  const [errorToastType, setErrorToastType] = useState<
    "added" | "updated" | "deleted" | null
  >();

  const { mutateAsync: updateUser } = useMutation(updateUsers, {
    onSuccess: (res) => {
      setSuccessToastType("updated");
      setTimeout(() => {
        setSuccessToastType(null);
      }, 3000);
      if (res.status === "error") {
        setErrorToastType("updated");
        setTimeout(() => {
          setErrorToastType(null);
        }, 3000);
      } else {
        setUsers((users: User[]) =>
          users.map((user) => (user.id === res.data.id ? res.data : user))
        );
        navigate("/users");
      }
    },
  });

  const { mutateAsync: submitUser } = useMutation(signUpUser, {
    onSuccess: (res) => {
      setSuccessToastType("added");
      setTimeout(() => {
        setSuccessToastType(null);
      }, 3000);
      if (res.status === "error") {
        setErrorToastType("added");
        setTimeout(() => {
          setErrorToastType(null);
        }, 3000);
      } else {
        setUsers((users: User[]) => [...users, res.data.user]);
        navigate("/users");
      }
    },
  });

  const submitData = async (data: FormAuthData) => {
    const { ...tempData } = data;

    const response = await submitUser({ ...tempData });
  };
  const inputSize = useBreakpointValue({ base: "sm", sm: "md" });

  const onSubmit = (data: FormEditData) => {
    updateUser({
      email: editingUser ? editingUser.email : "",
      info: data,
    });
  };
  const handleConfirmSubmit = () => {
    handleEditSubmit(onSubmit)();
    onClose();
  };
  const handleAddUserSubmit = () => {
    handleSubmit(submitData)();
    onAddUserClose();
  };

  const Size = useBreakpointValue({
    base: "1.9",
    sm: "1.5",
    md: "1",
    lg: "1",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [userSettings, setUserSettings] = useState("1");

  const fuse = useMemo(() => {
    if (users) {
      const options = {
        keys: ["email"],
        threshold: 0.3,
      };
      return new Fuse(users, options);
    }
  }, [users]);

  const filteredUsers = searchInput
    ? fuse?.search(searchInput).map((result) => result.item)
    : users;

  const dataColor = useColorModeValue("white", "gray.800");
  const bg = useColorModeValue("white", "gray.800");
  const bg2 = useColorModeValue("gray.100", "gray.700");
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAddUserOpen,
    onOpen: onAddUserOpen,
    onClose: onAddUserClose,
  } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching data.</div>;
  }

  return (
    <>
      <Grid
        w="full"
        h={"100vh"}
        gap={5}
        templateRows="repeat(8, 1fr)"
        templateColumns={isMobile ? "1" : "repeat(3, 1fr)"}
        dir="rtl"
      >
        <GridItem rowSpan={1} w={Size} h="15%">
          <NavBar />
        </GridItem>
        {isMobile ? (
          <MobileAdminPanel />
        ) : (
          <>
            <GridItem
              rowSpan={5}
              rowStart={2}
              colSpan={2}
              mr={10}
              mt={20}
              borderRadius={"lg"}
            >
              <Stack
                direction={{
                  base: "column",
                }}
                h={"75vh"}
                overflowY={"scroll"}
                bg={{
                  md: bg,
                }}
                shadow="lg"
                borderRadius={"lg"}
                border={"1px"}
                borderColor={"gray.100"}
              >
                <div
                  style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    background: "#DAC0A3",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr", // Adjust column layout as needed
                    textTransform: "uppercase",
                    fontSize: "md",
                    fontWeight: "bold",
                    padding: "4px 10px", // Adjust padding as needed
                    textAlign: "right",
                    direction: "rtl",
                    color: "black",
                  }}
                >
                  <Text mr={"10"}>שם</Text>
                  <Text mr={"10"}>אימייל</Text>
                  <Text ml={"10"} justifySelf={"flex-end"}>
                    פעולות
                  </Text>
                </div>

                {filteredUsers?.map((user, pid) => {
                  return (
                    <Flex
                      direction={{
                        base: "row",
                        md: "column",
                      }}
                      bg={dataColor}
                      key={pid}
                    >
                      <SimpleGrid
                        spacingY={3}
                        columns={{
                          base: 1,
                          md: 3,
                        }}
                        w="full"
                        py={2}
                        px={10}
                        fontWeight="hairline"
                      >
                        <Flex gap={2}>
                          <Text>{user.firstName} </Text>
                          <Text>{user.lastName}</Text>
                        </Flex>
                        <Text whiteSpace="nowrap">{user.email}</Text>
                        <Flex
                          justify={{
                            md: "end",
                          }}
                        >
                          <ButtonGroup variant="solid" size="sm" spacing={3}>
                            <IconButton
                              colorScheme="green"
                              icon={<AiFillEdit />}
                              aria-label="Edit"
                              onClick={() => {
                                handleEdit(user);
                                setUserSettings("2");
                              }}
                            />
                            <DeleteDialog user={user} />
                          </ButtonGroup>
                        </Flex>
                      </SimpleGrid>
                      <Divider />
                    </Flex>
                  );
                })}
                {filteredUsers?.length === 0 && (
                  <Flex justifyContent={"center"} alignItems={"center"}>
                    <Image boxSize={"md"} src={notFoundGif} alt="LOGO" />
                  </Flex>
                )}
              </Stack>
            </GridItem>
            <GridItem colStart={3} rowStart={2}>
              <Box flex="1" marginTop={20} h={"5vh"}>
                <Input
                  w={"60%"}
                  placeholder="חפש/י משתמש"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <RadioGroup
                  onChange={setUserSettings}
                  value={userSettings}
                  mt={5}
                >
                  <Stack direction="row">
                    <Radio value="1">הוסף משתמש</Radio>
                    <Radio value="2">ערוך משתמש</Radio>
                  </Stack>
                </RadioGroup>
              </Box>
            </GridItem>
            {editingUser && (
              <>
                {userSettings === "1" ? (
                  <>
                    <GridItem colStart={3} rowStart={3} mt={8}>
                      <Box
                        maxW={"md"}
                        mx={"auto"}
                        rounded={"lg"}
                        border={"1px"}
                        borderColor={"gray.100"}
                        bg="whiteAlpha.200"
                        boxShadow={"lg"}
                        p={10}
                      >
                        <Center>
                          <Heading size="md">הוסף משתמש</Heading>
                        </Center>
                        <form onSubmit={handleSubmit(submitData)}>
                          <Stack spacing={4}>
                            <FormField
                              label="כתובת אימייל:"
                              error={errors?.email?.message}
                            >
                              <Input
                                type="email"
                                size={inputSize}
                                {...register("email")}
                              />
                            </FormField>
                            <FormField
                              label="סיסמה:"
                              error={errors?.password?.message}
                            >
                              <InputGroup>
                                <Input
                                  {...register("password")}
                                  color={"black"}
                                  size={inputSize}
                                  type={showPassword ? "text" : "password"}
                                />
                                <InputLeftElement h={"full"}>
                                  <Button
                                    variant={"unstyled"}
                                    onClick={() =>
                                      setShowPassword(
                                        (showPassword) => !showPassword
                                      )
                                    }
                                  >
                                    {showPassword ? (
                                      <ViewIcon />
                                    ) : (
                                      <ViewOffIcon />
                                    )}
                                  </Button>
                                </InputLeftElement>
                              </InputGroup>
                            </FormField>
                            <FormField
                              label="שם פרטי:"
                              error={errors?.firstName?.message}
                            >
                              <Input type="text" {...register("firstName")} />
                            </FormField>

                            <FormField
                              label="שם משפחה:"
                              error={errors?.lastName?.message}
                            >
                              <Input type="text" {...register("lastName")} />
                            </FormField>
                            <Stack direction={"row"} align="center">
                              <FormField
                                label="מנהל מערכת"
                                error={errors?.isAdmin?.message}
                              >
                                <Checkbox {...register("isAdmin")} />
                              </FormField>
                              <Spacer />

                              <FormField
                                label="משתמש Gold"
                                error={errors?.isGold?.message}
                              >
                                <Checkbox {...register("isGold")} />
                              </FormField>
                              <Spacer />
                              <FormField
                                label="האחת"
                                error={errors?.isSilver?.message}
                              >
                                <Checkbox {...register("isSilver")} />
                              </FormField>
                            </Stack>

                            <Stack
                              spacing={10}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <Button
                                w={"20%"}
                                bg={"green.400"}
                                color={"white"}
                                onClick={onAddUserOpen}
                                _hover={{
                                  bg: "green.300",
                                }}
                              >
                                הוספה
                              </Button>
                            </Stack>
                          </Stack>
                          <AlertDialog
                            isOpen={isAddUserOpen}
                            leastDestructiveRef={cancelRef}
                            onClose={onAddUserClose}
                            isCentered
                          >
                            <AlertDialogOverlay>
                              <AlertDialogContent dir={"rtl"}>
                                <AlertDialogHeader
                                  fontSize="lg"
                                  fontWeight="bold"
                                >
                                  הוספת משתמש
                                </AlertDialogHeader>

                                <AlertDialogBody>
                                  האם את/ה בטוח/ה שהינך רוצה הוסיף משתמש זה
                                </AlertDialogBody>

                                <AlertDialogFooter>
                                  <Button
                                    ref={cancelRef}
                                    onClick={onAddUserClose}
                                    ml={5}
                                  >
                                    לא
                                  </Button>
                                  <Button
                                    colorScheme="red"
                                    onClick={handleAddUserSubmit}
                                    ml={3}
                                  >
                                    כן, בטוח
                                  </Button>
                                </AlertDialogFooter>
                              </AlertDialogContent>
                            </AlertDialogOverlay>
                          </AlertDialog>
                        </form>
                      </Box>
                    </GridItem>
                  </>
                ) : (
                  <>
                    <GridItem colStart={3} rowStart={3} mt={8}>
                      <Box
                        maxW={"md"}
                        mx={"auto"}
                        rounded={"lg"}
                        border={"1px"}
                        borderColor={"gray.100"}
                        bg="whiteAlpha.200"
                        boxShadow={"lg"}
                        p={10}
                      >
                        <form
                          // onSubmit={handleEditSubmit(onSubmit)}
                          key={editingUser?.id}
                        >
                          <Center>
                            <Heading size={"md"} mb={"5"}>
                              עריכת משתמש
                            </Heading>
                          </Center>
                          <Stack spacing={4} w={"100%"}>
                            <FormField
                              label="כתובת אימייל:"
                              error={errorsEdit?.email?.message}
                            >
                              <Input
                                type="email"
                                size={inputSize}
                                {...registerEdit("email")}
                              />
                            </FormField>
                            <FormField
                              label="שם פרטי:"
                              error={errors?.firstName?.message}
                            >
                              <Input
                                type="text"
                                {...registerEdit("firstName")}
                              />
                            </FormField>

                            <FormField
                              label="שם משפחה:"
                              error={errors?.lastName?.message}
                            >
                              <Input
                                type="text"
                                {...registerEdit("lastName")}
                              />
                            </FormField>
                            <Stack w={"100%"} direction={"row"}>
                              <FormField
                                label="מנהל מערכת"
                                // error={errorsEdit?.isAdmin?.message}
                              >
                                <Checkbox {...registerEdit("isAdmin")} />
                              </FormField>

                              <FormField
                                label="משתמש Gold"
                                // error={errorsEdit?.isGold?.message}
                              >
                                <Checkbox {...registerEdit("isGold")} />
                              </FormField>
                              <FormField
                                label="האחת"
                                // error={errorsEdit?.isSilver?.message}
                              >
                                <Checkbox {...registerEdit("isSilver")} />
                              </FormField>
                            </Stack>

                            <Stack
                              spacing={10}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <Button
                                w={"20%"}
                                bg={"green.400"}
                                color={"white"}
                                onClick={onOpen}
                                _hover={{
                                  bg: "green.300",
                                }}
                              >
                                שמירה
                              </Button>
                            </Stack>
                          </Stack>
                          <AlertDialog
                            isOpen={isOpen}
                            leastDestructiveRef={cancelRef}
                            onClose={onClose}
                            isCentered
                          >
                            <AlertDialogOverlay>
                              <AlertDialogContent dir={"rtl"}>
                                <AlertDialogHeader
                                  fontSize="lg"
                                  fontWeight="bold"
                                >
                                  עריכת משתמש
                                </AlertDialogHeader>

                                <AlertDialogBody>
                                  האם את/ה בטוח/ה שהינך רוצה לשמור שינויים
                                  למשתמש זה
                                </AlertDialogBody>

                                <AlertDialogFooter>
                                  <Button
                                    ref={cancelRef}
                                    onClick={onClose}
                                    ml={5}
                                  >
                                    לא
                                  </Button>
                                  <Button
                                    colorScheme="red"
                                    onClick={handleConfirmSubmit}
                                    ml={3}
                                  >
                                    כן, בטוח
                                  </Button>
                                </AlertDialogFooter>
                              </AlertDialogContent>
                            </AlertDialogOverlay>
                          </AlertDialog>
                        </form>
                      </Box>
                    </GridItem>
                  </>
                )}
              </>
            )}
            <GridItem gridRow={8} colSpan={3} dir="ltr">
              <Footer />
            </GridItem>
          </>
        )}
      </Grid>
      <SuccessToast successToastType={successToastType} />
      <ErrorToast errorToastType={errorToastType} />
    </>
  );
};

export default withAdminProtectedRoute(AdminPanel);
