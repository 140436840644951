import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { BsFillTrashFill } from "react-icons/bs";
import { useMutation } from "@tanstack/react-query";
import { Navigate, useNavigate } from "react-router-dom";
import { DeleteUser, User } from "../../api/Home";
import ErrorToast from "./ErrorToast";
import SuccessToast from "./SuccessToast";
import { useStore } from "../../api/store";

const DeleteDialog = (user: any) => {
  const { users, setUsers } = useStore();

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [successToastType, setSuccessToastType] = useState<
    "added" | "updated" | "deleted" | null
  >();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [errorToastType, setErrorToastType] = useState<
    "added" | "updated" | "deleted" | null
  >();
  const { mutateAsync: deleteUser } = useMutation(DeleteUser, {
    onSuccess: (res) => {
      console.log(res);
      setSuccessToastType("deleted");
      setTimeout(() => {
        setSuccessToastType(null);
      }, 3000);
      if (res.status === "error") {
        setErrorToastType("deleted");
        setTimeout(() => {
          setErrorToastType(null);
        }, 3000);
      } else {
        setUsers((users: User[]) =>
          users.filter((user) => user.id !== res.data)
        );
        navigate("/users");
      }
    },
  });

  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  const handleDiscard = () => {
    setIsOpen(false);
  };
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      <IconButton
        colorScheme="red"
        variant="outline"
        icon={<BsFillTrashFill />}
        aria-label="Delete"
        onClick={onOpen}
      />
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent dir="rtl" w={isMobile ? "xs" : "md"}>
          <AlertDialogHeader>מחיקת משתמש</AlertDialogHeader>
          <AlertDialogBody>
            האם את/ה בטוח/ה שהינך רוצה למחוק משתמש זה
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} ml={5}>
              לא
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                deleteUser({ id: user.user.id });
                onClose();
              }}
            >
              כן, בטוח
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <ErrorToast errorToastType={errorToastType} />
      <SuccessToast successToastType={successToastType} />
    </>
  );
};

export default DeleteDialog;
