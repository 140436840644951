// Inside the SuccessToast component
import React from "react";
import { Box, useToast } from "@chakra-ui/react";

export type ErrorToastProps = {
  errorToastType?: "added" | "updated" | "deleted" | null;
};

const ErrorToast: React.FC<ErrorToastProps> = ({ errorToastType }) => {
  const toast = useToast();

  React.useEffect(() => {
    if (errorToastType) {
      const toastMessage =
        errorToastType === "added" ? (
          <Box dir="rtl">שגיאה בהוספת משתמש!</Box>
        ) : errorToastType === "updated" ? (
          <Box dir="rtl">שגיאה בעריכת משתמש!</Box>
        ) : (
          <Box dir="rtl">שגיאה במחיקת משתמש!</Box>
        );
      toast({
        title: toastMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [errorToastType, toast]);

  return null; // We don't need to render anything in this component
};

export default ErrorToast;
