import axios from "axios";

const client = axios.create({ baseURL: "https://bakery-avia.com" });

function authorizationRequest(config: any, tokenType: string) {
  let token = localStorage.getItem(tokenType) as string;
  if (token != null) {
    if (config.defaults) {
      config.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      config.headers["Authorization"] = "Bearer " + token;
    }
    // config.headers.set("Authorization", "Bearer " + token);
  } else {
    if (tokenType === "refreshToken") {
      window.location.replace("/");
    }
  }
}

client.interceptors.request.use(
  (config) => {
    if (config.url === "/api/auth/refresh") {
      authorizationRequest(config, "refreshToken");
    } else {
      authorizationRequest(config, "accessToken");
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalReq = error.config;
    if (
      error?.response?.status === 401 &&
      originalReq.url === "/api/auth/refresh"
    ) {
      window.location.replace("/");
      return Promise.reject(error);
    }
    if (error?.response?.status === 401 && !originalReq._retry) {
      originalReq._retry = true;
      return client.get("/api/auth/refresh").then((res) => {
        if (res?.status === 200) {
          localStorage.setItem("accessToken", res.data.access_token);
          authorizationRequest(client, "accessToken");
          return client(originalReq);
        }
      });
    }
    return Promise.reject(error);
  }
);

export default client;
