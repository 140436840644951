import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Center,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Input,
  Flex,
  Button,
  Text,
  InputGroup,
  Icon,
  Image,
  InputRightElement,
  InputLeftElement,
} from "@chakra-ui/react";
import { MdOutlineWarning } from "react-icons/md";
import { BsWhatsapp } from "react-icons/bs";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import HomeFile from "../HomeFile";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { ZodType, z } from "zod";
import { useMutation } from "@tanstack/react-query";
import { signIn } from "../../api/AuthApi";
import { FormField } from "./FormField";
import logo from "../../assets/LOGO-AVIA.png";
export type signInData = {
  email: string;
  password: string;
};

export default function SigninCard() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isError, setIsError] = useState(false);
  const SignAuth: ZodType<signInData> = z.object({
    email: z.string().email().min(15).max(30),
    password: z.string().min(4).max(30),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<signInData>({
    resolver: zodResolver(SignAuth),
  });
  const { mutateAsync: signInUser } = useMutation(signIn, {
    onSuccess: (res) => {
      console.log(res);
      if (res.data.statusCode === "error") {
        setIsError(true);
        alert("password and email do not match");
      } else navigate("/home");
    },
  });

  const submitData = async (data: signInData) => {
    await signInUser(data);
  };

  return (
    <form onSubmit={handleSubmit(submitData)} dir="rtl">
      <Center flexDirection={"column"} bg="#fff4f4" h="100vh">
        <Image src={logo} alt="LOGO" />

        <Card
          display="flex"
          alignItems="center"
          border="1px solid"
          color="black"
          bg="whiteAlpha.100"
          boxShadow={"2xl"}
        >
          <CardHeader
            display="flex"
            alignItems="center"
            justifyContent="center"
            h="20%"
          >
            <Text fontSize="3xl" as="b">
              התחברות
            </Text>
          </CardHeader>

          <CardBody h="50%">
            <Flex flexDir={"column"} gap={3}>
              <FormField label="אימייל" error={errors?.email?.message}>
                <Input
                  border={"1px"}
                  borderRadius={"lg"}
                  borderColor={"gray.400"}
                  placeholder="אימייל"
                  type="email"
                  {...register("email", {
                    required: true,
                  })}
                />
                {errors.email && (
                  <Flex alignItems="center" justifyContent="space-between">
                    <Icon color="red.600" as={MdOutlineWarning} />
                    אימייל לא תקין
                  </Flex>
                )}
              </FormField>
              <FormField label="סיסמא" error={errors?.password?.message}>
                <InputGroup>
                  <Input
                    {...register("password")}
                    color={"black"}
                    w={"100%"}
                    type={showPassword ? "text" : "password"}
                  />
                  <InputLeftElement h={"full"}>
                    <Button
                      variant={"unstyled"}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputLeftElement>
                </InputGroup>
                <Link to="/forgetPassword">שכחתי סיסמה?</Link>
                {errors.password && (
                  <Flex alignItems="center" justifyContent="space-between">
                    <Icon color="red.600" as={MdOutlineWarning} />
                    סיסמה חייבת להיות מעל 4 תווים
                  </Flex>
                )}
                {isError === true && (
                  <Flex alignItems="center" justifyContent="space-between">
                    <Icon color="red.600" as={MdOutlineWarning} />
                    אחד או יותר מהפרטים שגוי
                  </Flex>
                )}
              </FormField>
            </Flex>
          </CardBody>

          <CardFooter
            w="90%"
            h="30%"
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
          >
            <Button
              type="submit"
              color={"#FEFFFF"}
              _hover={{ bg: "green.300" }}
              bg="green.400"
              p="1%"
              w="40%"
            >
              התחבר
            </Button>
            <Flex w={"70%"}>
              <Text>לקוח חדש?</Text>
              <Link to={"https://wa.link/7wjrj1"}>
                <Text
                  fontWeight={"semibold"}
                  mr={1}
                  variant={"unstyled"}
                  h={"5px"}
                >
                  צור קשר
                </Text>
              </Link>
              {/* <BsWhatsapp size={16} /> */}
            </Flex>
          </CardFooter>
        </Card>
      </Center>
    </form>
  );
}
