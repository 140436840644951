// Inside the SuccessToast component
import React from "react";
import { Box, useToast } from "@chakra-ui/react";

export type SuccessToastProps = {
  successToastType?: "added" | "updated" | "deleted" | null;
};

const SuccessToast: React.FC<SuccessToastProps> = ({ successToastType }) => {
  const toast = useToast();

  React.useEffect(() => {
    if (successToastType) {
      const toastMessage =
        successToastType === "added" ? (
          <Box dir="rtl">משתמש נוסף בהצלחה!</Box>
        ) : successToastType === "updated" ? (
          <Box dir="rtl">משתמש נערך בהצלחה!</Box>
        ) : (
          <Box dir="rtl">משתמש נמחק בהצלחה!</Box>
        );
      toast({
        title: toastMessage,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [successToastType, toast]);

  return null; // We don't need to render anything in this component
};

export default SuccessToast;
