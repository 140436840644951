import { create } from "zustand";
import { User } from "./Home";

type State = {
  users: User[];
  setUsers: (users: User[] | ((prevUsers: User[]) => User[])) => void;
};

export const useStore = create<State>((set) => ({
  users: [],
  setUsers: (users) =>
    set((prevState) => ({
      users: typeof users === "function" ? users(prevState.users) : users,
    })),
}));