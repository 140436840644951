import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Center,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Input,
  Flex,
  Button,
  Text,
  Icon,
  Image,
  InputGroup,
} from "@chakra-ui/react";
import { MdOutlineWarning } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../../assets/LOGO-AVIA.png";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // const signIn = useSignIn();
  const [isError, setIsError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [access, setAccess] = useState(true);
  const onSubmit = async (data: any) => {
    try {
      const response = await axios.post(
        "https://bakery-avia.com/api/auth/forgetPassword",
        data
      );

      // signIn({
      //   token: response.data,
      //   expiresIn: 3600,
      //   tokenType: "Bearer",
      //   authState: { email: data.email },
      // });

      if (response.data.status === "error") {
        setIsError(true);
        console.log("error");
      } else if (response.data.statusCode === "success") {
        setAccess(false);
        setIsError(false);
        console.log(access);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitForResetPassword = async (data: any) => {
    if (password === passwordConfirm) {
      setIsError(false);

      try {
        const response = await fetch(
          "https://bakery-avia.com/api/auth/editPassword",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              password,
              email: data.email,
            }),
          }
        );

        if (response.ok) {
          setIsError(false);
          setPassword("");
          setPasswordConfirm("");
          navigate("/");
        } else {
          const data = await response.json();
          setIsError(data.message);
        }
      } catch (error) {
        setIsError(true);
      }
    } else {
      setIsError(true);
    }
  };

  return (
    <>
      {!access ? (
        <form onSubmit={handleSubmit(onSubmitForResetPassword)} dir="rtl">
          <Center bg="#fff4f4" h="100vh" flexDir={"column"}>
            <Flex justifyContent={"center"}>
              <Image src={logo} />
            </Flex>
            <Card
              display="flex"
              alignItems="center"
              border="1px solid"
              color="black"
              bg="whiteAlpha.100"
              boxShadow={"2xl"}
            >
              <CardHeader
                display="flex"
                alignItems="center"
                justifyContent="center"
                h="20%"
              >
                <Text fontSize="3xl" as="b">
                  אפס סיסמה
                </Text>
              </CardHeader>

              <CardBody>
                <Flex flexDir={"column"} gap={3}>
                  <Heading fontSize="md">סיסמה</Heading>

                  <InputGroup
                    border={"1px"}
                    borderRadius={"lg"}
                    borderColor={"gray.400"}
                  >
                    <Input
                      textAlign={"right"}
                      type={"text"}
                      value={password}
                      placeholder="סיסמה"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                  <Heading fontSize="md">אשר סיסמה</Heading>

                  <InputGroup
                    border={"1px"}
                    borderRadius={"lg"}
                    borderColor={"gray.400"}
                  >
                    <Input
                      textAlign={"right"}
                      type={"text"}
                      placeholder="אשר סיסמה"
                      value={passwordConfirm}
                      onChange={(e) => setPasswordConfirm(e.target.value)}
                    />
                  </InputGroup>

                  {errors.password && (
                    <Flex alignItems="center" justifyContent="space-between">
                      <Icon color="red.600" as={MdOutlineWarning} />
                      סיסמה חייבת להיות מעל 4 תווים
                    </Flex>
                  )}
                </Flex>
                {isError ? <Flex>הסיסמה לא נכונה</Flex> : <></>}
              </CardBody>

              <CardFooter
                w="90%"
                h="30%"
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
              >
                <Button
                  // isDisabled={errors.password || errors.email}
                  type="submit"
                  bg="green.200"
                  p="1%"
                  w="40%"
                >
                  אפס
                </Button>
              </CardFooter>
            </Card>
          </Center>
        </form>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} dir="rtl">
          <Center bg="#fff4f4" h="100vh" flexDir={"column"}>
            <Flex justifyContent={"center"}>
              <Image src={logo} />
            </Flex>
            <Card
              display="flex"
              alignItems="center"
              border="1px solid"
              color="black"
              bg="whiteAlpha.100"
              boxShadow={"2xl"}
            >
              <CardHeader
                display="flex"
                alignItems="center"
                justifyContent="center"
                h="20%"
              >
                <Text fontSize="3xl" as="b">
                  איפוס סיסמה
                </Text>
              </CardHeader>

              <CardBody>
                <Flex flexDir={"column"} gap={3}>
                  <Heading fontSize="md">הכנס אימייל</Heading>
                  <Input
                    border={"1px"}
                    borderRadius={"lg"}
                    borderColor={"gray.400"}
                    placeholder="אימייל"
                    type="email"
                    {...register("email", {
                      required: true,
                      //   pattern: {
                      //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      //   },
                    })}
                  />
                  <Link to="/"> חזור לכניסה !</Link>
                  {errors.email && (
                    <Flex alignItems="center" justifyContent="space-between">
                      <Icon color="red.600" as={MdOutlineWarning} />
                      אימייל לא תקין
                    </Flex>
                  )}
                </Flex>
              </CardBody>

              <CardFooter
                w="90%"
                h="30%"
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
              >
                <Button
                  // isDisabled={errors.email}
                  type="submit"
                  bg="green.200"
                  p="1%"
                  w="40%"
                >
                  אישור
                </Button>
              </CardFooter>
            </Card>
          </Center>
        </form>
      )}
    </>
  );
};

export default ForgetPassword;
