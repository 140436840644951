import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Image,
  useBreakpointValue,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

import { HamburgerIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { GetUser, User } from "../api/Home";
import logo from "../assets/LOGO-AVIA.png";

function NavBar() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  const Size = useBreakpointValue({
    base: "100%",
    sm: "90%",
    md: "90%",
    lg: "90",
  });
  const LogoSize = useBreakpointValue({
    base: "35",
    sm: "30%",
    md: "70%",
    lg: "20%",
  });

  const {
    data: Myuser,
    isLoading,
    isError,
    refetch,
  } = useQuery<User>(["Myuser"], GetUser, { refetchInterval: 60000 });

  const [currentUrl, setCurrentUrl] = useState(window.location.href);

  useEffect(() => {
    // Update the current URL whenever the component mounts or the URL changes
    const handleUrlChange = () => {
      setCurrentUrl(window.location.href);
    };

    window.addEventListener("popstate", handleUrlChange);

    return () => {
      window.removeEventListener("popstate", handleUrlChange);
    };
  }, []);
  const parts = currentUrl.split("/");
  const endPoint = parts[parts.length - 1];
  return (
    <Box
      position="fixed"
      w="100%"
      zIndex={2}
      bg="#f7e6c4"
      display="flex"
      justifyContent="space-between"
      minH={"7vh"}
      color="black"
      dir="rtl"
    >
      <Flex w={LogoSize}>
        <Image src={logo} w={"150px"} alt="LOGO" />
      </Flex>

      <Menu>
        <MenuButton
          ml={5}
          display={["flex", "flex", "none", "none"]}
          aria-label="Open Menu"
        >
          {<HamburgerIcon />}
        </MenuButton>
        <MenuList zIndex={1} dir="rtl">
          {Myuser?.isAdmin === true && endPoint === "users" && (
            <MenuItem
              onClick={() => {
                navigate("/home");
              }}
              color="black"
              bg="none"
            >
              מסך הבית
            </MenuItem>
          )}
          {Myuser?.isAdmin === true && endPoint != "users" && (
            <MenuItem
              onClick={() => {
                navigate("/users");
              }}
              color="black"
              bg="none"
            >
              ניהול מערכת
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              onOpen();
            }}
          >
            אודות
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/");
            }}
          >
            התנתק
          </MenuItem>

        </MenuList>
      </Menu>

      <Center
        dir="rtl"
        display={["none", "none", "flex", "flex"]}
        justifyContent="right"
      >
        <Button
          onClick={() => {
            navigate("/");
          }}
          color="black"
          bg="none"
        >
          התנתק
        </Button>
        <Button
          onClick={() => {
            onOpen();
          }}
          bg="none"
          color="black"
        >
          אודות
        </Button>
        {Myuser?.isAdmin && endPoint === "users" ? (
          <Button
            onClick={() => {
              navigate("/home");
            }}
            color="black"
            bg="none"
          >
            מסך הבית
          </Button>
        ) : (
          Myuser?.isAdmin === true && (
            <Button
              onClick={() => {
                navigate("/users");
              }}
              color="black"
              bg="none"
            >
              ניהול מערכת
            </Button>
          )
        )}
        <Box>
          <Modal size={"xl"} isCentered isOpen={isOpen} onClose={onClose}>
            <ModalContent>
              <ModalHeader alignSelf={"center"}>אודות</ModalHeader>
              <ModalCloseButton />
              <ModalBody bg="white">
                <Text color="black" dir="rtl" w="90%">
                  <Text fontWeight={"bold"} mb={3}>
                    תיאור הקורס
                  </Text>
                  שלום לכולם , איזה כיף שאתם מצטרפים אליי לסדנא.
                  <br />
                  הסדנא עם המוצר הכי הכי מדובר , הקרואסון! ולא סתם קרואסון ,
                  הדרך לקרואסון המושלם.
                  <br />
                  <Text fontWeight={"semibold"} mb={"3"} mt={"3"}>
                    למי הקורס מתאים
                  </Text>
                  הסדנא מתאימה לכולם! מה אופה הביתי ועד לאופה המקצועי , בסדנא
                  תקבלו טיפים איך לעבוד מהבית וטיפים איך לייעל את העבודה שלכם
                  בין אם בבית ובין אם בעסק.
                  <br />
                  <Text fontWeight={"semibold"} mb={"3"} mt={"3"}>
                    אודות המרצה
                  </Text>
                  אז שוב שלום , אני אביה אדר , הפכתי את התחביב למקצוע , למדתי
                  באופן מקצועי עברתי סטאז׳ וכל הזמן רק שיפרתי את עצמי ואספתי את
                  הסודות שלי , ובאתי לגלות לכם את כל הסודות שאספתי , מישראל ועד
                  פריז.
                  <br />
                  <br />* קיימות זכיות יוצרים על כל הסירטונים ועל כל תוכן הקורס
                  אדם אשר מעביר או מעתיק או כל דבר אשר לא לשימוש עצמי בלעדי יהיה
                  חשוף לתביעה!
                </Text>
              </ModalBody>
              <ModalFooter bg="white">
                <Flex alignItems="center" dir="rtl" direction="column">
                  <Text fontWeight={"bold"} alignSelf={"flex-start"} mb={3}>
                    מדיניות גישה לנכים
                  </Text>
                  <Text color="black">
                    * החברה עשתה את כל שביכולתה בכדי לתת שירות לנכים ברמה
                    המקסימלית כולל גישה לנכים. האחריות על שאר הדברים הרצויים שלא
                    נימצאים באתר הקורס מוטלת על המשתמש בקורס ולא על בעליו
                    <br />
                    .בתודה רבה והערכה מאביה אדר
                  </Text>
                  <Button
                    color="black"
                    mt={5}
                    onClick={() => {
                      onClose();
                    }}
                  >
                    סגירה
                  </Button>
                </Flex>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      </Center>
    </Box>
  );
}

export default NavBar;
