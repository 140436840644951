import React from "react";
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Image,
  Link,
  Spacer,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { BsFacebook, BsInstagram, BsWhatsapp } from "react-icons/bs";
import { AiFillLinkedin, AiOutlineMail } from "react-icons/ai";
import {
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa";
import logo from "../assets/logo.png";

function Footer() {
  const LinkSize = useBreakpointValue({
    base: "70%",
    sm: "50%",
    md: "40%",
    lg: "30%",
  });
  const LogoSize = useBreakpointValue({
    base: "30%",
    sm: "50%",
    md: "60%",
    lg: "70%",
  });
  return (
    <Box as="footer" py={4} px={6} bg="#606c5d" color="white" dir="rtl">
      <Flex alignItems="center">
        <Box width={["100px", "120px", "150px"]} maxWidth="100%">
          <Image src={logo} alt="Logo" height={30} />
        </Box>
        <Spacer />
        <Flex alignItems="center">
          <Link href="https://www.instagram.com/aviaella/?igshid=MmIzYWVlNDQ5Yg==">
            <Icon as={FaInstagram} boxSize={6} mr={10} />
          </Link>
          <Link href="https://www.facebook.com/avia.shoshan.7?mibextid=b06tZ0">
            <Icon as={FaFacebook} boxSize={6} mr={10} />
          </Link>
          <Link href="https://wa.link/7wjrj1">
            <Icon as={FaWhatsapp} boxSize={6} mr={10} />
          </Link>
          {/* <Icon as={FaEnvelope} boxSize={6} /> */}
        </Flex>
      </Flex>
      <Box textAlign="center" mt={2} fontWeight={"semibold"} fontSize={"xs"}>
        N.M AMBITION © {new Date().getFullYear()}
      </Box>
    </Box>
  );
}

export default Footer;
