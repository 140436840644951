import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import HomeFile from "./components/HomeFile";
import SigninCard from "./components/auth/Login";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ForgetPassword from "./components/auth/ForgetPassword";
import DisableContextMenu from "./components/DisableContextMenu";
import AdminPanel from "./components/admin/AdminPanel";
const router = createBrowserRouter([
  {
    path: "/home",
    element: <HomeFile />,
  },
  {
    path: "/users",
    element: <AdminPanel />,
  },
  {
    path: "/forgetPassword",
    element: <ForgetPassword />,
  },
  {
    path: "/",
    element: <SigninCard />,
  },
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

function App() {
  return (
    <ChakraProvider>
      <QueryClientProvider client={queryClient}>
        <DisableContextMenu />
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default App;
