import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { GetUser, Geturls, User, Users } from "../api/Home";
function Cards() {
  const SizeVideo = useBreakpointValue({
    base: "300px",
    sm: "400px",
    md: "500px",
    lg: "600px",
  });
  const SizeAccordin = useBreakpointValue({
    base: "300px",
    sm: "400px",
    md: "500px",
    lg: "800px",
  });
  const SectionsSize = useBreakpointValue({
    base: "3%",
    sm: "5%",
    md: "7%",
    lg: "10%",
  });
  const DrawerSize = useBreakpointValue({
    base: "60%",
    sm: "43%",
    md: "30%",
    lg: "20%",
  });

  const [selectedVideo, setSelectedVideo] = useState<number | null>(0);

  const {
    data: urls,
    isLoading,
    isError,
    refetch,
  } = useQuery<Users[]>(["Myvids"], Geturls, { staleTime: Infinity });

  const AllItems = urls;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  return (
    <Grid templateRows="repeat(1,1fr)" gap={4} mt="3%">
      <Flex justify="center" alignItems="center">
        <Box>
          {AllItems?.map((url, index) => {
            const adjustedIndex = AllItems?.length === 1 ? 11 : index;
            return (
              <GridItem key={index}>
                {selectedVideo === index && (
                  <>
                    <Flex
                      direction="column"
                      alignItems="center"
                      justifyContent="space-around"
                    >
                      <Center
                        fontFamily={"fantasy"}
                        // fontStyle="italic"
                        fontSize={"xx-large"}
                      />
                      <Center fontSize={"xx-large"}>{url.name}</Center>
                      <ReactPlayer
                        width={SizeVideo}
                        url={`https://bakery-avia.com/api/static/${
                          adjustedIndex + 1
                        }.mp4`}
                        controls
                        config={{
                          file: {
                            attributes: {
                              controlsList: "nodownload",
                            },
                          },
                        }}
                      />

                      {url?.description && (
                        <Accordion dir="rtl" allowMultiple>
                          <AccordionItem>
                            <AccordionButton w={SizeVideo}>
                              <Box w="30%" as="span" flex="1" textAlign="right">
                                הסבר {url.name}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel w={SizeVideo} pb={6}>
                              <Text
                                alignItems="self-end"
                                fontFamily={"fantasy"}
                                fontStyle="italic"
                                fontSize="30px"
                              >
                                {url.name}
                              </Text>
                              <Box dir="rtl" p="3" fontFamily={"sans-serif"}>
                                {url?.description
                                  .split("\n")
                                  .map((i: string, key: number) => {
                                    return <p key={key}>{i}</p>;
                                  })}
                              </Box>
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      )}
                      <Button
                        mt="2%"
                        bg="gray.400"
                        color="black"
                        onClick={onOpen}
                      >
                        שלבים נוספים
                      </Button>
                    </Flex>
                  </>
                )}
              </GridItem>
            );
          })}
        </Box>

        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          size={"30%"}
        >
          <DrawerOverlay />
          <DrawerContent borderRadius={"3%"} h="480px" maxWidth={DrawerSize}>
            <DrawerCloseButton />

            <DrawerBody>
              <Box dir="rtl" p={4}>
                <Heading size="md" mb={4} dir="rtl">
                  שלבים
                </Heading>
                <VStack fontSize={"80%"} spacing={2} align="start">
                  {AllItems?.map((url, index) => (
                    <Text
                      key={index}
                      onClick={() => {
                        onClose();
                        setSelectedVideo(index);
                      }}
                      _hover={{ cursor: "pointer", color: "blue.500" }}
                      textAlign={"left"}
                    >
                      {url.name}
                    </Text>
                  ))}
                </VStack>
              </Box>
            </DrawerBody>

            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                חזור
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Flex>

      <Text fontSize={"2xl"} textAlign={"center"}>
        :מתכונים
      </Text>
      <GridItem>
        <Center
          dir="rtl"
          textAlign={"center"}
          display={"flex"}
          flexDirection={"column"}
          w={"100%"}
        >
          <Accordion allowMultiple>
            <AccordionItem textAlign={"center"}>
              <AccordionButton w={SizeVideo}>קרם שקדים</AccordionButton>
              <AccordionPanel w={SizeAccordin} pb={6}>
                <Box dir="rtl" p="3" fontFamily={"sans-serif"}>
                  <br />
                  <Text fontSize={"2xl"}>קרם שקדים</Text>
                  300 גרם חמאה
                  <br />
                  300 גרם אבקת שקדים
                  <br />
                  300 גרם אבקת סוכר
                  <br />
                  30 גרם קורנפלור
                  <br />
                  2 גרם מלח
                  <br />
                  30 גרם רום כהה
                  <br />
                  180 גרם ביצים
                  <br />
                  <Text fontSize={"2xl"}>אופן הכנה:</Text>
                  בקערת מיקסר עם וו גיטרה נערבב את החמאה ואבקת הסוכר עד לקבלת
                  קרם אחיד.
                  <br />
                  נוסיף את אבקת השקדים, מלח והקורנפלור ונערבב היטב נוסיף את
                  הביצים והרום ונערבב עד לתערובת אחידה.
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Accordion allowMultiple>
            <AccordionItem textAlign={"center"}>
              <AccordionButton w={SizeVideo}>קרם פטיסייר</AccordionButton>
              <AccordionPanel w={SizeAccordin} pb={6}>
                <Box dir="rtl" p="3" fontFamily={"sans-serif"}>
                  <Text fontSize={"2xl"}> קרם פטיסייר</Text>
                  <br />
                  500 גרם חלב
                  <br />
                  מחית וניל
                  <br />
                  110 גרם סוכר
                  <br />
                  120 גרם חלמונים
                  <br />
                  40 גרם קורנפלור
                  <br />
                  50 גרם חמאה
                  <br />
                  <Text fontSize={"2xl"}> אופן הכנה</Text>
                  <br />
                  בקערה נערבב יחד את החלמונים עם הקורנפלור, בקלחת מרתיחים את
                  החלב עם הווניל והסוכר וכשהגענו לרתיחה נעשה השוואת טמפרטורות
                  יחד עם הקורנפלור והחלמונים , נחזיר לכיריים ונבשל להסמכה תוך
                  כדי בחישה מתמדת. כשהגענו להסמכה הרצויה נכבה את האש ונוסיף את
                  החמאה , נערבב לאחידות ונצנן את הקרם.
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Accordion allowMultiple>
            <AccordionItem textAlign={"center"}>
              <AccordionButton w={SizeVideo}>
                קולי מנגו פסיפלורה
              </AccordionButton>
              <AccordionPanel w={SizeAccordin} pb={6}>
                <Box dir="rtl" p="3" fontFamily={"sans-serif"}>
                  <Text fontSize={"2xl"}> קולי מנגו פסיפלורה</Text>
                  <br />
                  קולי מנגו פסיפלורה
                  <br />
                  250 גרם מחית מנגו
                  <br />
                  250 גרם מחית פסיפלורה
                  <br />
                  70 גרם סוכר
                  <br />
                  10 גרם פקטין NH
                  <br />
                  <Text fontSize={"2xl"}> אופן הכנה</Text>
                  <br />
                  בקערה נערבב את הסוכר יחד עם הפקטין. בקלחת נחמם ל 40 מעלות את
                  מחית המנגו ומחית הפסיפלורה הגענו ל 40 מעלות נוסיף את הסוכר
                  והפקטין נבשל תוך כדי ערבוב עד לבעבוע (בין דקה לשתיים לא נרצה
                  לבשל את המחיות פרי יותר מדי)
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Center>
      </GridItem>
    </Grid>
  );
}

export default Cards;
