import { Component, FunctionComponent, ReactNode, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "@chakra-ui/react";
import { Route, redirect, RouteProps, useNavigate } from "react-router-dom";
import { GetUser, User } from "../api/Home";

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  //ghghj
  const {
    data: Myuser,
    isLoading,
    isError,
    refetch,
  } = useQuery<User>(["Myuser"], GetUser, { refetchInterval: 60000 });
  const navigate = useNavigate();

  if (isLoading) {
    return <Spinner size={"xl"} color="pink.200" />;
  }

  return <>{Myuser ? <>{children}</> : <>{navigate("/")}</>}</>;
};

export const withProtectedRoute = <T extends Record<string, unknown>>(
  Component: FunctionComponent<T>
) => {
  return function (props: T): JSX.Element {
    return (
      <ProtectedRoute>
        <Component {...props} />
      </ProtectedRoute>
    );
  };
};
